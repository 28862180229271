import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';
import ReactGA from 'react-ga';

import * as API from '../apis';
import { slugify } from '../utils/slug';
import { ACTION, CATEGORY, LABEL } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Video from '../components/Video';
import VideoPlaceholder from '../components/Placeholder/Video';
import MutedText from '../components/MutedText';
import { countViews } from '../utils/misc';
import NotFoundPic from '../images/not-found.jpg';

const VideoList = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-bottom: 1.875rem;
`;

const Title = styled.h1`
  margin: 0 0 1.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: #10abe2;
`;

function SearchPage({ location }) {
  const { q } = queryString.parse(location.search);
  const [resultVideos, setResultVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchVideos = async () => {
      try {
        setLoading(true);
        const response = await API.searchVideos(q, { limit: 100 });
        setLoading(false);
        setResultVideos(response.data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }

      ReactGA.event({
        category: CATEGORY.VIDEO,
        action: ACTION.SEARCH,
        value: q,
      });
    };

    searchVideos();
  }, [q]);

  return (
    <Layout>
      <SEO title="Tìm kiếm" />

      <Title>Kết quả tìm kiếm</Title>
      {!loading && !(resultVideos && resultVideos.length) && (
        <>
          <MutedText>Không tìm thấy video nào</MutedText>
          <br />
          <img src={NotFoundPic} alt="Không tìm thấy kết quả" />
        </>
      )}
      <VideoList>
        {loading && (
          <>
            <VideoPlaceholder />
            <VideoPlaceholder />
            <VideoPlaceholder />
            <VideoPlaceholder />
          </>
        )}
        {!loading &&
          resultVideos &&
          resultVideos.map(video => (
            <Video
              key={video.code}
              title={video.name}
              subtitle={video.subject && video.subject.name}
              thumbnail={video.thumbnail}
              link={`videos/${slugify(video.name)}`}
              duration={video.duration}
              viewCount={countViews(video.views, video.demoViews)}
              publishDate={video.publishDate}
              type={video.contentType}
              gaLabel={LABEL.SEARCH_PAGE}
            />
          ))}
      </VideoList>
    </Layout>
  );
}

SearchPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SearchPage;
